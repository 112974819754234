/*
 * @Author: dingguowei
 * @Date: 2023-05-18 11:26:03
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-05-29 16:12:28
 */
import request from '@/plugins/axios'
const base = '/'

// 用户名登录
export function userlogin(data) {
    return request({
      url:  base + 'api/user/login',
      headers: { // 修改请求头
        'Content-Type': 'application/json'
      },
      method: 'post',
      data
    })
}

// 手机号登录
export function phonelogin(data) {
  return request({
    url:  base + 'api/user/mobile/login',
    method: 'post',
    data
  })
}
  // 注册
export function save(data) {
  return request({
    url:  base + 'api/user/save',
    headers: { // 修改请求头
      'Content-Type': 'application/json'
    },
    method: 'post',
    data
  })
}
// 手机号验证

export function phonemsg(params) {
  return request({
    url:  base + 'api/user/sms/get',
    method: 'get',
    params
  })
}